<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex">
        <button
          class="rounded-md text-black"
          @click="
            $router.push({
              name: 'PermissionsDepartment',
            })
          "
        >
          < Back
        </button>
      </div>
      <h1 class="text-2xl my-5 font-bold text-blue-800">
        {{ this.$route.params.name }} Departments Permissions
      </h1>

      <div class="flex flex-row mt-5">
        <div class="float-right">
          <input
            type="text"
            class="inline-block mx-2 px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-3"
            placeholder="Search permissions"
            id=""
            v-model="search"
          />
        </div>
        <!-- <button
          class="bg-blue-600 py-2 px-6 rounded-md text-white text-sm"
          @click="searchRecords"
        >
          Search
        </button> -->
      </div>
      <div class="flex flex-row mt-5" style="max-height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <th
              class="text-l p-3 text-left"
              colspan="17"
              style="background: #dee6f2"
            >
              List of Permissions
            </th>
            <tr style="background: #fff">
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Permissions
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Description
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Status
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(permission, i) in filterPermission" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ permission.name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ permission.description || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ permission.status || "N/A" }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <input
                  type="checkbox"
                  role="switch"
                  id="ms1"
                  ref="switch"
                  :checked="permission.status == 'INACTIVE' ? '' : 'checked'"
                  @change="handleToggle(permission)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="alertDiv"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <i
          class="bi bi-check-circle-fill text-green-400 text-6xl"
          v-if="currPermission.status === 'INACTIVE'"
        ></i>
        <i class="bi bi-x-circle-fill text-yellow-400 text-6xl" v-else></i>
        <p class="text-xl font-bold w-3/4 mx-auto text-center">
          {{ messg }}
        </p>
        <small class="text-xs"
          >Kindly confirm that you want to perform this action below.</small
        >
        <div
          class="flex justify-between w-3/4 mt-5 mx-auto"
          v-if="currPermission.status === 'INACTIVE'"
        >
          <button
            @click="handlePermissionChange"
            class="uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
          >
            Turn on permission
          </button>
          <button
            @click="handleCloseAlert"
            class="uppercase shadow mx-auto hover:bg-red-600 bg-red-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
          >
            Cancel
          </button>
        </div>
        <div class="flex justify-between w-3/4 mt-5 mx-auto" v-else>
          <button
            @click="removeUserPermission"
            class="uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
          >
            Turn off permission
          </button>
          <button
            @click="handleCloseAlert"
            class="uppercase shadow mx-auto hover:bg-red-600 bg-red-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 px-6 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: true,
      currentPage: 1,
      search: "",
      alertDiv: false,
      currPermission: "",
      messg: "",
    };
  },
  computed: {
    ...mapGetters(["GET_DEPARTMENT_PERMISSIONS"]),
    filterPermission() {
      let res =
        this.GET_DEPARTMENT_PERMISSIONS.data.permissions &&
        this.GET_DEPARTMENT_PERMISSIONS.data.permissions.filter((permission) =>
          permission.name.toLowerCase().includes(this.search.toLowerCase())
        );
      return res;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("FETCH_DEPT_PERMISSION_BY_DEPT_ID", {
          id: this.$route.params.id,
        });
        this.isLoading = false;
      } catch (error) {}
    },
    async handleCloseAlert() {
      this.alertDiv = false;
      this.fetchRecords();
    },
    async handleToggle(permission) {
      this.alertDiv = true;
      this.currPermission = permission;
      this.messg =
        permission.status === "INACTIVE"
          ? "Do you want to activate this permission for this user."
          : "Do you want to deactivate this permission for this user.";

      this.$refs.switch.checked = true;
    },
    async handlePermissionChange() {
      this.isLoading = true;
      let payload = {
        dept_id: this.$route.params.id,
        permission: this.currPermission.name,
      };
      try {
        const res = await this.$store.dispatch(
          "SET_DEPT_PERMISSION_BY_DEPT_ID",
          payload
        );
        this.isLoading = false;
        this.alertDiv = false;
        if (res.status) {
          this.fetchRecords();
        }
      } catch (error) {}
    },

    async removeUserPermission() {
      this.isLoading = true;
      let payload = {
        dept_id: this.$route.params.id,
        permission: this.currPermission.name,
      };
      try {
        const res = await this.$store.dispatch(
          "REMOVE_DEPT_PERMISSION_BY_DEPT_ID",
          payload
        );
        this.isLoading = false;
        this.alertDiv = false;
        if (res.status) {
          this.fetchRecords();
        }
      } catch (error) {}
    },
    searchRecords() {
      return null;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input:where([type="checkbox"][role="switch"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: inherit;
  font-size: inherit;
  width: 2em;
  height: 1em;
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: auto;
}

input:where([type="checkbox"][role="switch"])::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.15em;
  border: 1px solid;
  border-radius: 50%;
  background: currentcolor;
}

input:checked {
  background-color: #253985;
}
input:where([type="checkbox"][role="switch"]):checked::before {
  left: 1em;
  background-color: #fff;
}

input:where([type="checkbox"][role="switch"]):disabled {
  opacity: 0.4;
}
</style>
